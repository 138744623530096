<template>
    <div class="ytm-container" style="margin-top: 1.5rem">
        <div style="display: flex; margin-bottom: 2rem">
            <div style="display: table; margin-right: 1rem">
                <div style="display: table-cell; vertical-align: middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="ytm-admin-back-button" @click="$router.push('/admin')">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/>
                    </svg>
                </div>
            </div>
            <div style="display: table">
                <p class="ytm-default-text" style="display: table-cell; vertical-align: middle; font-size: 2rem; font-weight: 600">
                    Отправка рассылок
                </p>
            </div>
        </div>
        <p class="ytm-default-text" style="margin-bottom: 2rem">
            Сервис админской рассылки электронных писем.
        </p>
        <div style="display: flex; margin-bottom: 1rem; height: 3rem">
            <div style="flex-grow: 1">
                <div class="ytm-tabs-titles">
                    <div
                        :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': showLetters}"
                        @click="selectTab(true)"
                    >
                        Письма
                    </div>
                    <div
                        :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': !showLetters}"
                        @click="selectTab(false)"
                    >
                        Рассылки
                    </div>
                </div>
            </div>
            <div v-show="showLetters">
                <div class="ytm-black-button" style="display: table" @click="createNewLetter">
                    <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 1.2rem; display: table-cell; vertical-align: middle; margin-right: 0.8rem">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 3C20.5 1.61929 19.3807 0.5 18 0.5C16.6193 0.5 15.5 1.61929 15.5 3V14.5H3C1.61929 14.5 0.5 15.6193 0.5 17C0.5 18.3807 1.61929 19.5 3 19.5H15.5V32C15.5 33.3807 16.6193 34.5 18 34.5C19.3807 34.5 20.5 33.3807 20.5 32V19.5H32C33.3807 19.5 34.5 18.3807 34.5 17C34.5 15.6193 33.3807 14.5 32 14.5H20.5V3Z" fill="white"/>
                    </svg>
                    <span class="ytm-default-text ytm-create-course" style="font-weight: 600; color: #FFFFFF; display: table-cell; vertical-align: middle">
                        Создать письмо
                    </span>
                </div>
            </div>
        </div>
        <div v-show="showLetters">
            <div v-if="letters === null" class="ytm-default-text">
                Письма загружаются...
            </div>
            <div v-else-if="letters.length === 0" class="ytm-default-text">
                Пока нет писем
            </div>
            <div v-else>
                <table class="ytm-mailing-table">
                    <tr>
                        <th>ID письма</th>
                        <th>Название</th>
                        <th>Автор</th>
                        <th>Когда создано</th>
                        <th/>
                    </tr>
                    <tr v-for="letter in letters" :key="letter.id" class="ytm-mailing-table-row" @click="$router.push('/admin/mailing/edit_letter/' + letter.id)">
                        <td>{{letter.id}}</td>
                        <td>{{letter.name}}</td>
                        <td>{{letter.author}}</td>
                        <td>{{getStrDate(letter.createdAt)}}</td>
                        <td style="display: flex">
                            <div class="ytm-black-button ytm-default-text" style="color: #FFFFFF; font-weight: 600" @click="createNewMailing(letter.id)">
                                + Рассылка с этим письмом
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div v-show="!showLetters">
            <div v-if="mailings === null" class="ytm-default-text">
                Рассылки загружаются...
            </div>
            <div v-else-if="mailings.length === 0" class="ytm-default-text">
                Пока нет рассылок
            </div>
            <div v-else>
                <table class="ytm-mailing-table">
                    <tr>
                        <th>ID рассылки</th>
                        <th>Название</th>
                        <th>Автор</th>
                        <th>ID письма</th>
                        <th>Когда создана</th>
                        <th>Когда отправлена</th>
                    </tr>
                    <tr v-for="mailing in mailings" :key="mailing.id" class="ytm-mailing-table-row" @click="$router.push('/admin/mailing/edit_mailing/' + mailing.id)">
                        <td>{{mailing.id}}</td>
                        <td>{{mailing.name}}</td>
                        <td>{{mailing.creator}}</td>
                        <td>{{mailing.letter ? mailing.letter.id || '' : ''}}</td>
                        <td>{{getStrDate(mailing.createdAt)}}</td>
                        <td>{{getStrDate(mailing.sentAt)}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import axios from "axios";
import {SERVICE_MAILING_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
    name: 'Mailing',
    data() {
        return {
            showLetters: true,
            letters: null,
            mailings: null,
        };
    },
    methods: {
        getStrDate(dt) {
            return dt ? (new Date(dt)).toLocaleDateString() + ' ' + (new Date(dt)).toLocaleTimeString() : '–';
        },
        selectTab(showLetters) {
            if (showLetters) {
                this.showLetters = true;
                this.$router.replace({query: {section: 'letters'}});
            } else {
                this.showLetters = false;
                this.$router.replace({query: {section: 'mailings'}});
            }
        },
        loadLetters() {
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.get(SERVICE_MAILING_URI + '/admin/letter/get/', {
                    headers: authHeader(),
                }).then(resp => {
                    this.letters = (resp.data.out || []).reverse();
                }).catch(err => {
                    console.log(err);
                });
            });
        },
        loadMailings() {
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.get(SERVICE_MAILING_URI + '/admin/mailing/get/', {
                    headers: authHeader(),
                }).then(resp => {
                    this.mailings = (resp.data.out || []).reverse();
                }).catch(err => {
                    console.log(err);
                });
            });
        },
        createNewLetter() {
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.post(SERVICE_MAILING_URI + '/admin/letter/set', {},
                    {headers: authHeader()},
                ).then(resp => {
                    const id = resp.data.out.id;
                    this.$router.push('/admin/mailing/edit_letter/' + id);
                }).catch(err => {
                    console.log(err);
                });
            });
        },
        createNewMailing(letterId) {
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.post(SERVICE_MAILING_URI + '/admin/mailing/set', {letterId: letterId},
                    {headers: authHeader()},
                ).then(resp => {
                    const id = resp.data.out.id;
                    this.$router.push('/admin/mailing/edit_mailing/' + id);
                }).catch(err => {
                    console.log(err);
                });
            });
        },
    },
    mounted() {
        this.selectTab(this.$route.query.section !== 'mailings');
        this.loadLetters();
        this.loadMailings();
    },
};
</script>

<style>
.ytm-admin-back-button {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}
.ytm-mailing-table {
    font-family: 'Montserrat', sans-serif;
    border-collapse: collapse;
    width: 100%;
}
.ytm-mailing-table td, th {
    border-bottom: 1px solid #191847;
    text-align: left;
    padding: 1.2vw;
}
.ytm-mailing-table-row {
    cursor: pointer;
}
.ytm-mailing-table-row:hover {
    background-color: #efefef;
}
</style>