<template>
    <div class="ytm-container" style="margin-top: 1.5rem">
        <div style="display: flex; margin-bottom: 2rem">
            <div style="display: table; margin-right: 1rem">
                <div style="display: table-cell; vertical-align: middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="ytm-admin-back-button" @click="$router.push('/admin/mailing?section=mailings')">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/>
                    </svg>
                </div>
            </div>
            <div style="display: table">
                <p class="ytm-default-text" style="display: table-cell; vertical-align: middle; font-size: 2rem; font-weight: 600">
                    Редактирование рассылки
                </p>
            </div>
        </div>
        <div v-if="mailing">
            <p v-if="mailing.sentAt" class="ytm-default-text" style="margin-bottom: 1.5rem; font-size: 1.2rem; font-weight: 600">
                Рассылка отправлена
            </p>
            <InputGroup
                class="ytm-modal-input"
                v-model="mailing.name"
                title="Название рассылки"
                placeholder="Будет отображаться в админке"
                :focus="true"
                :disabled="mailing.sentAt ? 'disabled' : null"
                @update:modelValue="changed = true"
                style="margin-bottom: 1rem"
            />
            <InputGroup
                class="ytm-modal-input"
                v-model="mailing.letter.id"
                title="ID письма"
                placeholder="Идентификатор письма из списка писем"
                type="number"
                :disabled="mailing.sentAt ? 'disabled' : null"
                @update:modelValue="changed = true"
                style="margin-bottom: 1rem"
            />
            <TextAreaGroup
                v-model="emailsStr"
                title="Список получателей (по одному на строку)"
                placeholder="Электронные адреса по одному на строку"
                :rows="4"
                :disabled="mailing.sentAt ? 'disabled' : null"
                @update:modelValue="changed = true"
                style="margin-bottom: 1rem"
            />
            <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: 1rem; text-align: center; color: red">
                {{errorMsg}}
            </p>
            <div style="display: flex; justify-content: center; margin-bottom: 5rem">
                <div
                    v-if="changed"
                    class="ytm-blue-button ytm-default-text"
                    style="color: #FFFFFF; font-weight: 600"
                    @click="saveMailing"
                >
                    Сохранить
                </div>
                <div
                    v-else-if="canSend"
                    class="ytm-blue-button ytm-default-text"
                    style="color: #FFFFFF; font-weight: 600"
                    @click="broadcastMailing"
                >
                    Отправить
                </div>
            </div>
        </div>
        <div v-else class="ytm-default-text">
            Загрузка...
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import InputGroup from "@/components/redesign/Form/InputGroup.vue";
import TextAreaGroup from "@/components/redesign/Form/TextAreaGroup.vue";
import {checkEmail} from "@/util/check-email";
import axios from "axios";
import {SERVICE_MAILING_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
    name: 'EditMailing',
    components: {TextAreaGroup, InputGroup},
    computed: {
        canSend() {
            return this.mailing
                && !this.mailing.sentAt
                && this.mailing.name && this.mailing.name.length > 0
                && this.mailing.letter && this.mailing.letter.id > 0
                && this.mailing.recipients && this.mailing.recipients.length > 0;
        },
    },
    data() {
        return {
            mailing: null,
            emailsStr: '',
            broadcasting: false,
            changed: false,
            errorMsg: '',
        };
    },
    methods: {
        loadMailing() {
            this.errorMsg = '';
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.get(SERVICE_MAILING_URI + '/admin/mailing/get/' + Number(this.$route.params.mailingId), {
                    headers: authHeader(),
                }).then(resp => {
                    this.mailing = resp.data.out;
                    if (!this.mailing.letter) {
                        this.mailing.letter = {};
                    }
                    if (this.mailing.recipients) {
                        this.emailsStr = this.mailing.recipients.join('\n');
                    } else {
                        this.emailsStr = '';
                    }
                }).catch(err => {
                    this.errorMsg = err;
                });
            });
        },
        getEmailsList() {
            const strs = this.emailsStr.split('\n');
            for (let i = 0; i < strs.length; i++) {
                if (!checkEmail(strs[i])) {
                    this.errorMsg = 'Ошибка: строка #' + (i + 1) + ' ("' + strs[i] + '") не проходит проверку – ожидаемый формат ilove@youteachme.ru';
                    return [];
                }
            }
            return strs;
        },
        saveMailing() {
            this.errorMsg = '';
            const recipients = this.getEmailsList();
            if (this.errorMsg) {
                return;
            }
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.post(SERVICE_MAILING_URI + '/admin/mailing/set', {
                    id: this.mailing.id,
                    name: this.mailing.name,
                    letterId: Number(this.mailing.letter.id),
                    recipients: recipients,
                }, {headers: authHeader()}).then(() => {
                    this.loadMailing();
                    this.changed = false;
                }).catch(err => {
                    this.errorMsg = err;
                });
            });
        },
        broadcastMailing() {
            if (this.broadcasting) {
                return;
            }
            this.errorMsg = '';
            this.$store.dispatch('auth/autoLogin').then(() => {
                this.broadcasting = true;
                axios.post(
                    SERVICE_MAILING_URI + '/admin/mailing/broadcast/' + this.mailing.id,
                    {},
                    {headers: authHeader()},
                ).then(() => {
                    this.loadMailing();
                    this.broadcasting = false;
                }).catch(err => {
                    this.errorMsg = err;
                    this.broadcasting = false;
                });
            });
        },
    },
    mounted() {
        this.loadMailing();
    },
};
</script>

<style>
.ytm-admin-back-button {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}
.CodeMirror * {
    font-family: "JetBrains Mono", monospace;
    font-size: 0.8rem;
}
</style>