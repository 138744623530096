<template>
    <div>
        <div class="ytm-container" style="margin-top: 1.5rem">
            <div style="display: flex; margin-bottom: 2rem">
                <div style="display: table; margin-right: 1rem">
                    <div style="display: table-cell; vertical-align: middle">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="ytm-admin-back-button" @click="$router.push('/admin/mailing?section=letters')">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/>
                        </svg>
                    </div>
                </div>
                <div style="display: table">
                    <p class="ytm-default-text" style="display: table-cell; vertical-align: middle; font-size: 2rem; font-weight: 600">
                        Редактирование письма
                    </p>
                </div>
            </div>
        </div>
        <div v-if="letter">
            <div class="ytm-container" style="margin-top: 1.5rem">
                <InputGroup
                    class="ytm-modal-input"
                    v-model="letter.name"
                    title="Название письма"
                    placeholder="Будет отображаться в таблице в админке"
                    :focus="true"
                    @update:modelValue="changed = true"
                    style="margin-bottom: 1rem"
                />
                <InputGroup
                    class="ytm-modal-input"
                    v-model="letter.subject"
                    title="Заголовок письма"
                    placeholder="Будет показан в уведомлении на почте"
                    @update:modelValue="changed = true"
                    style="margin-bottom: 1rem"
                />
            </div>
            <div style="padding: 0 5%; display: flex; width: 100%; margin-bottom: 3rem">
                <div style="width: 49%">
                    <p class="ytm-default-text ytm-input-group-title">
                        Тело письма (HTML)
                    </p>
                    <Codemirror
                        v-model:value="letter.HTMLBody"
                        :options="cmOptions"
                        border
                        placeholder=""
                        ref="editor"
                        :onChange="() => {changed = true}"
                        style="margin-bottom: 1rem"
                    />
                </div>
                <div style="width: 49%; margin-left: 2%">
                    <p class="ytm-default-text ytm-input-group-title">
                        Превью
                    </p>
                    <div v-html="wrappedHTML"/>
                </div>
            </div>
            <div>
                <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: 1rem; text-align: center; color: red">
                    {{errorMsg}}
                </p>
                <div v-if="changed" style="display: flex; justify-content: center; margin-bottom: 5rem">
                    <div
                        class="ytm-blue-button ytm-default-text"
                        style="color: #FFFFFF; font-weight: 600"
                        @click="saveLetter"
                    >
                        Сохранить
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="ytm-container ytm-default-text">
            Загрузка...
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchtags';
import 'codemirror/addon/edit/closetag';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/lib/codemirror.css';
import Codemirror from 'codemirror-editor-vue3';
import InputGroup from "@/components/redesign/Form/InputGroup.vue";
import axios from "axios";
import {SERVICE_MAILING_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import * as util from "util";

export default {
    name: 'EditLetter',
    components: {InputGroup, Codemirror},
    computed: {
        wrappedHTML() {
            return util.format(this.template, this.letter.subject, this.letter.HTMLBody);
        },
    },
    data() {
        return {
            letter: null,
            template: null,
            cmOptions: {
                mode: 'text/html',
                lineNumbers: true,
                smartIndent: true,
                indentUnit: 2,
                foldGutter: true,
                styleActiveLine: true,
                autoCloseBrackets: true,
                autoCloseTags: true,
                matchClosingTags: true,
                matchTags: {bothTags: true},
            },
            changed: false,
            errorMsg: '',
        };
    },
    methods: {
        loadLetter() {
            this.errorMsg = '';
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.get(SERVICE_MAILING_URI + '/admin/letter/get/' + Number(this.$route.params.letterId), {
                    headers: authHeader(),
                }).then(resp => {
                    this.letter = resp.data.out.letter;
                    this.template = resp.data.out.template;
                }).catch(err => {
                    this.errorMsg = err;
                });
            });
        },
        saveLetter() {
            this.errorMsg = '';
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.post(SERVICE_MAILING_URI + '/admin/letter/set', {
                    id: this.letter.id,
                    name: this.letter.name,
                    subject: this.letter.subject,
                    body: this.letter.HTMLBody,
                }, {headers: authHeader()}).then(() => {
                    this.loadLetter();
                    this.changed = false;
                }).catch(err => {
                    this.errorMsg = err;
                });
            });
        },
    },
    mounted() {
        this.loadLetter();
    },
};
</script>

<style>
.ytm-admin-back-button {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}
.CodeMirror * {
    font-family: "JetBrains Mono", monospace;
    font-size: 0.8rem;
}
</style>