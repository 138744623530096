<template>
  <input
      :value="modelValue"
      @input="updateInput"
      class="ytm-input"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
  >
</template>

<script>
import "@/styles/style.css";

export default {
  name: 'Input',
  props: {
    modelValue: {},
    type: {type: String},
    placeholder: {type: String},
    disabled: {type: String},
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style scoped>
.ytm-input {
  width: 100%;
  padding: 0.75rem 1.25rem;
  background: #F3F3F3;
  border-radius: 0.6rem;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-style: normal;
  line-height: 116.6%;
  font-weight: 400;
  overflow: hidden;
}

.ytm-input:focus {
  outline: none;
}
</style>