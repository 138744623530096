<template>
  <div class="ytm-input-group">
    <p class="ytm-default-text ytm-input-group-title">
      {{title}}
    </p>
    <Input
        ref="inputField"
        v-model="modelValue"
        :placeholder="placeholder"
        :type="type"
        :disabled="disabled"
        @update:modelValue="updateInput"
    />
  </div>
</template>

<script>
import "@/styles/style.css";
import "./forms.css";
import Input from "@/components/redesign/Form/Input";

export default {
  name: 'InputGroup',
  components: {Input},
  props: {
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {},
    focus: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: String,
      default: null,
    },
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event);
    },
  },
  mounted() {
    if (this.focus) {
      this.$refs.inputField.$el.focus();
    }
  },
};
</script>

<style scoped>

</style>