<template>
    <div class="ytm-input-group">
        <p class="ytm-default-text ytm-input-group-title">
            {{title}}
        </p>
        <TextArea
                ref="inputField"
                v-model="modelValue"
                :placeholder="placeholder"
                :disabled="disabled"
                :rows="rows"
                @update:modelValue="updateInput"
        />
    </div>
</template>

<script>
import "@/styles/style.css";
import "./forms.css";
import TextArea from "@/components/redesign/Form/TextArea.vue";

export default {
    name: 'TextAreaGroup',
    components: {TextArea},
    props: {
        title: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        modelValue: {},
        focus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: String,
            default: null,
        },
        rows: {
            type: Number,
            default: 4,
        },
    },
    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event);
        },
    },
    mounted() {
        if (this.focus) {
            this.$refs.inputField.$el.focus();
        }
    },
};
</script>

<style scoped>

</style>